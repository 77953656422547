import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../../components/Layout/index'
import PressRelease from '../../components/PressRelease'
import SEO from '../../components/seo'
import Footer from '../../modules/Footer'
import Header from '../../modules/Header'

import Image from '../../images/news/news_ewh_teaser.png'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import messages from '../../messages/pageTitles.lang'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsTwo)}
        imageAlt={formatMessage(messages.TitleNewsTwo)}
        description={formatMessage(metaDescription.NewsTwoDescription)}
        image={Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.eurowings}
        articleLinks={{
          tsbo: <Link to="/products/travelsandbox/">travelsandbox®</Link>,
          ewh: (
            <a href="https://holidays.eurowings.com/" target="_blank" rel="noopener noreferrer">
              Eurowings Holidays
            </a>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
